<template>
	<div v-if="!isMobile" id="plane" ref="plane">
		<i aria-hidden="true">✈</i>
	</div>
</template>

<script setup>
import { onMounted, ref } from 'vue'

const plane = ref(null)
const deg = ref(0)
const ex = ref(0)
const ey = ref(0)
const vx = ref(0)
const vy = ref(0)
const count = ref(0)
const isMobile = ref(false)

const draw = () => {
	plane.value.style.transform = 'rotate(' + deg.value + 'deg)'
	if (count.value < 100) {
		vx.value += ex.value / 100
		vy.value += ey.value / 100
	}
	plane.value.style.left = vx.value + 'px'
	plane.value.style.top = vy.value + 'px'
	count.value++
}

onMounted(() => {
	isMobile.value = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
	if (!isMobile.value) {
		window.addEventListener('mousemove', (e) => {
			ex.value = e.pageX - plane.value.offsetLeft - plane.value.clientWidth / 2
			ey.value = e.pageY - plane.value.offsetTop - plane.value.clientHeight / 2
			deg.value = (360 * Math.atan(ey.value / ex.value)) / (2 * Math.PI) + 5
			if (ex.value < 0) {
				deg.value += 180
			}
			count.value = 0
		})
		setInterval(draw, 1)
	}
})
</script>

<style lang="less" scoped>
#plane {
	color: #1792ff;
	font-size: 70px;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: default;
	pointer-events: none;
	bottom: -100%;
	left: -100%;
	width: 100px;
	height: 100px;
}
</style>
