<template>
	<div :class="['lantern-container', { scrolled: isScrolled }]" :style="{ top: `${isScrolled ? headerHeight : 0}px` }">
		<div v-for="(item, index) in lanternTexts" :key="item" :class="[`deng-${index + 1}`, 'deng-box common']">
			<div class="deng">
				<div class="xian"></div>
				<div class="deng-a">
					<div class="deng-b">
						<div class="deng-text">{{ item }}</div>
					</div>
				</div>
				<div class="lantern-bottom">
					<div class="bottom-c"></div>
					<div class="bottom-b"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const lanternTexts = ref(['欢', '度', '新', '春'])
const isScrolled = ref(false)

const headerHeight = computed(() => store.getters['common/headerHeight'])

function handleScroll() {
	isScrolled.value = window.scrollY > 300
}

onMounted(() => {
	window.addEventListener('scroll', handleScroll)
})
onUnmounted(() => {
	window.removeEventListener('scroll', handleScroll)
})
</script>

<style lang="less" scoped>
.lantern-container {
	width: 100%;
	position: fixed;
	top: 0;
	z-index: -1;
	transition: all 0.5s;
}

.deng-box {
	pointer-events: none;
	position: absolute;
}

.deng-1 {
	top: 20px;
	left: 40px;
}

.deng-2 {
	top: 20px;
	left: 160px;
}

.deng-3 {
	top: 20px;
	right: 160px;
}

.deng-4 {
	top: 20px;
	right: 40px;
}

.deng {
	position: relative;
	width: 120px;
	height: 90px;
	background: #d8000f;
	background: rgba(216, 0, 15, 0.8);
	border-radius: 50% 50%;
	transform-origin: 50% -100px;
	animation: swing 3s infinite ease-in-out;
	box-shadow: -5px 5px 50px 4px #fa6c00;
}

.xian {
	position: absolute;
	top: -40px;
	left: 50%;
	width: 2px;
	height: 40px;
	background: #dc8f03;
	transform: translateX(-50%);
}

.deng-a {
	width: 100px;
	height: 90px;
	background: #d8000f;
	background: rgba(216, 0, 15, 0.1);
	border-radius: 50% 50%;
	border: 2px solid #dc8f03;
	left: 50%;
	position: absolute;
	transform: translateX(-50%);
}

.deng-b {
	background: #d8000f;
	background: rgba(216, 0, 15, 0.1);
	border-radius: 50% 50%;
	border: 2px solid #dc8f03;
	left: 50%;
	position: absolute;
	transform: translateX(-50%);
}

.deng-text {
	font-size: 3.2rem;
	color: #dc8f03;
	font-weight: 700;
	line-height: 85px;
	text-align: center;
}

.lantern-bottom {
	position: absolute;
	width: 5px;
	height: 20px;
	left: 50%;
	transform: translateX(-50%);
	animation: swing 4s infinite ease-in-out;
	transform-origin: 50% -45px;
	background: orange;
	border-radius: 0 0 5px 5px;
	bottom: -25px;
}

.bottom-b {
	position: absolute;
	top: 14px;
	left: -2px;
	width: 10px;
	height: 10px;
	background: #dc8f03;
	border-radius: 50%;
}

.bottom-c {
	position: absolute;
	top: 18px;
	left: -2px;
	width: 10px;
	height: 35px;
	background: orange;
	border-radius: 0 0 0 5px;
}

.deng:before {
	position: absolute;
	top: -7px;
	left: 50%;
	transform: translateX(-50%);
	height: 12px;
	width: 60px;
	content: ' ';
	display: block;
	z-index: 999;
	border-radius: 5px 5px 0 0;
	border: solid 1px #dc8f03;
	background: orange;
	background: linear-gradient(to right, #dc8f03, orange, #dc8f03, orange, #dc8f03);
}

.deng:after {
	position: absolute;
	bottom: -7px;
	left: 50%;
	transform: translateX(-50%);
	height: 12px;
	width: 60px;
	content: ' ';
	display: block;
	border-radius: 0 0 5px 5px;
	border: solid 1px #dc8f03;
	background: orange;
	background: linear-gradient(to right, #dc8f03, orange, #dc8f03, orange, #dc8f03);
}

@media screen and (max-width: 768px) {
	.lantern-container {
		.common {
			transform: scale(0.5);
			transform-origin: top;
		}

		.deng-1 {
			top: 20px;
			left: 0;
		}

		.deng-2 {
			top: 20px;
			left: 80px;
		}

		.deng-3 {
			top: 20px;
			right: 80px;
		}

		.deng-4 {
			top: 20px;
			right: 0;
		}
	}
}

@keyframes swing {
	0% {
		transform: rotate(-10deg);
	}
	50% {
		transform: rotate(10deg);
	}
	100% {
		transform: rotate(-10deg);
	}
}
</style>
