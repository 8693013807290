<template>
	<div :class="['header', { scrolled: isScrolled }]">
		<div class="header-left"><img alt="" src="@/assets/image/logo.png" /></div>
		<div class="header-right">
			<span @click="scrollToTop">{{ t('home.首页') }}</span>
			<span @click="scrollTo('#content')">APP</span>
			<span @click="scrollTo('#footer')">{{ t('home.信息') }}</span>
			<span @click="switchLanguage">CN/EN</span>
			<span v-if="!username" @click="showLoginDialog = true">{{ t('home.登录') }}</span>
			<span v-if="!username" @click="showRegisterDialog = true">{{ t('home.注册') }}</span>
			<span v-else @click="router.push('/center')">{{ username }}</span>
			<span v-if="username"></span>
		</div>

		<!-- 登录 -->
		<el-dialog v-model="showLoginDialog" :title="t('home.登录')" align-center width="500">
			<el-form ref="ruleFormRef" :model="form" :rules="rules" label-width="auto" style="max-width: 600px">
				<el-form-item :label="t('home.账号')" prop="account">
					<el-input v-model="form.account" />
				</el-form-item>
				<el-form-item :label="t('home.密码')" prop="password">
					<el-input v-model="form.password" type="password" />
				</el-form-item>
			</el-form>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="showLoginDialog = false"> {{ t('common.取消') }}</el-button>
					<el-button type="primary" @click="login"> {{ t('home.登录') }}</el-button>
				</div>
			</template>
		</el-dialog>

		<!-- 注册 -->
		<el-dialog v-model="showRegisterDialog" :title="t('home.注册')" align-center width="800">
			<el-form ref="ruleFormRef" :model="registerForm" :rules="rules" label-width="auto" style="max-width: 800px">
				<el-form-item :label="t('home.邮箱')" prop="account">
					<el-input v-model="registerForm.account" />
				</el-form-item>
				<el-form-item :label="t('home.验证码')" prop="code">
					<div class="register-code">
						<el-input v-model="registerForm.code" />
						<span v-if="!isShowCount" class="count" @click="sendCode">{{ t('home.发送验证码') }}</span>
						<span v-else class="count disable">{{ count }} s</span>
					</div>
				</el-form-item>
				<el-form-item :label="t('home.密码')" prop="password">
					<el-input v-model="registerForm.password" show-password type="password" />
				</el-form-item>
				<el-form-item :label="t('home.确认密码')" prop="confirmPassword">
					<el-input v-model="registerForm.confirmPassword" show-password type="password" />
				</el-form-item>
			</el-form>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="showRegisterDialog = false"> {{ t('common.取消') }}</el-button>
					<el-button type="primary" @click="register"> {{ t('home.注册') }}</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { computed, onMounted, onUnmounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { encryptData } from '@/utils/encryption'
import { ElMessage } from 'element-plus'
import { sendSimpleMail } from '@/api/common'
import { registerUser } from '@/api/user'

const store = useStore()
const router = useRouter()
const { t } = useI18n()
const ruleFormRef = ref(null)
const showLoginDialog = ref(false)
const showRegisterDialog = ref(false)
const count = ref(60)
const isShowCount = ref(false)
const isScrolled = ref(false)
const timer = ref(null)
const username = computed(() => store.getters['user/username'])
const form = reactive({
	account: '',
	password: '',
})
const registerForm = reactive({
	account: '',
	password: '',
	confirmPassword: '',
	code: '',
})
const rules = {
	account: [
		{ required: true, message: 'Please input account', trigger: 'blur' },
		{ min: 8, max: 20, message: 'Length should be 8 to 20', trigger: 'blur' },
	],
	password: [
		{ required: true, message: 'Please input password', trigger: 'blur' },
		{ min: 8, max: 20, message: 'Length should be 8 to 20', trigger: 'blur' },
	],
	confirmPassword: [
		{ required: true, message: 'Please input confirm password', trigger: 'blur' },
		{ min: 8, max: 20, message: 'Length should be 8 to 20', trigger: 'blur' },
	],
	code: [{ required: true, message: 'Please input code', trigger: 'blur' }],
}
const language = computed(() => store.getters['common/language'])

function scrollTo(selector) {
	const element = document.querySelector(selector)
	if (element) {
		element.scrollIntoView({ behavior: 'smooth' })
	}
}

function scrollToTop() {
	window.scrollTo({ top: 0, behavior: 'smooth' })
}

function switchLanguage() {
	store.dispatch('common/setLanguage', language.value === 'en' ? 'zh-Hans' : 'en')
}

function login() {
	ruleFormRef.value.validate(async (valid) => {
		if (valid) {
			let data = {
				accountOrEmail: form.account,
				password: encryptData(form.password),
				type: form.account.includes('@') ? 0 : 1,
				token: '',
			}
			store.dispatch('user/login', data).then(() => {
				router.push('/center')
			})
		} else {
			return false
		}
	})
}

function register() {
	ruleFormRef.value.validate(async (valid) => {
		if (valid) {
			if (registerForm.password !== registerForm.confirmPassword) {
				return ElMessage.error(t('home.两次密码不一致'))
			}
			let data = {
				email: registerForm.account,
				password: encryptData(registerForm.password),
				emailCode: registerForm.code,
				time: new Date().getTime(),
			}
			const res = await registerUser(data)
			if (res.code !== 200) {
				return ElMessage.success(t('home.注册失败'))
			}
			ElMessage.success(t('home.注册成功'))
			showRegisterDialog.value = false
			let req = {
				accountOrEmail: registerForm.account,
				password: encryptData(registerForm.password),
				type: registerForm.account.includes('@') ? 0 : 1,
			}
			store.dispatch('user/login', req).then(() => {
				router.push('/center')
			})
		} else {
			return false
		}
	})
}

async function sendCode() {
	if (registerForm.account.trim() === '') {
		return ElMessage.error(t('home.邮箱不能为空'))
	}
	if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(registerForm.account)) {
		return ElMessage.error(t('home.邮箱格式不正确'))
	}
	let data = {
		email: registerForm.account,
		time: new Date().getTime(),
		module: '邮箱注册',
		moduleEn: 'Email register',
		isEn: 'en',
	}
	const res = await sendSimpleMail(data)
	if (res.code !== 200) {
		return ElMessage.success(t('home.发送失败'))
	}
	ElMessage.success(t('home.发送成功'))
	isShowCount.value = true
	const TIME_COUNT = 60
	if (!timer.value) {
		count.value = TIME_COUNT
		timer.value = setInterval(() => {
			if (count.value > 0 && count.value <= TIME_COUNT) {
				count.value--
			} else {
				isShowCount.value = false
				clearInterval(timer.value)
				timer.value = null
			}
		}, 1000)
	}
}

function handleScroll() {
	isScrolled.value = window.scrollY > 300
}

function startSetHeaderHeight() {
	setTimeout(() => {
		const headerHeight = document.querySelector('.header').offsetHeight
		store.dispatch('common/setHeaderHeight', headerHeight)
	}, 100)
}

function setHeaderHeight() {
	const headerHeight = document.querySelector('.header').offsetHeight
	store.dispatch('common/setHeaderHeight', headerHeight)
}

onMounted(() => {
	startSetHeaderHeight()
	window.addEventListener('scroll', handleScroll)
	window.addEventListener('resize', setHeaderHeight)
	if (language.value == null) {
		store.dispatch('common/setLanguage', 'en')
	}
})
onUnmounted(() => {
	window.removeEventListener('scroll', handleScroll)
	window.removeEventListener('resize', setHeaderHeight)
})

defineExpose({
	showLoginDialog,
})
</script>

<style lang="less" scoped>
.header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 120px;
	position: sticky;
	background: transparent;
	top: 0;
	z-index: 1;
	transition: all 0.5s;

	.header-left {
		width: 10%;

		img {
			width: 100%;
		}
	}

	.header-right {
		display: flex;
		justify-content: space-around;
		align-items: center;

		span {
			font-size: 18px;
			transition: all 0.5s;
			font-weight: bold;
			cursor: pointer;
			display: block;
			padding: 0 15px;
			height: 100%;
			line-height: 100%;

			&:hover {
				transform: scale(1.2);
			}
		}
	}

	.register-code {
		width: 100%;
		display: flex;
		align-items: center;

		.count {
			width: 30%;
			text-align: center;
			background: #409eff;
			border-radius: 8px;
			color: #fff;
			margin: 0 20px;
			cursor: pointer;
		}

		.disable {
			background: #ccc;
			pointer-events: none;
		}
	}
}

.scrolled {
	background-color: #fff;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@media (max-width: 768px) {
	.header {
		padding: 20px;

		.header-left {
			width: 20%;

			img {
				width: 100%;
			}
		}

		.header-right {
			width: 80%;
			flex-wrap: wrap;

			span {
				width: 30%;
				font-size: 12px;
				text-align: center;
				padding: 5px 0;
			}
		}

		.register-code {
			.count {
			}

			.disable {
			}
		}
	}
}
</style>
