import { getToken, setToken } from '@/utils/auth'
import i18n from '@/locale/index'

const state = {
	language: getToken('language'),
	headerHeight: 38,
}

const mutations = {
	SET_LANGUAGE: (state, language) => {
		i18n.global.locale.value = language
		state.language = language
	},
	setHeaderHeight: (state, height) => {
		state.headerHeight = height
	},
}

const actions = {
	setLanguage({ commit }, language) {
		commit('SET_LANGUAGE', language)
		setToken('language', language)
	},
	setHeaderHeight({ commit }, height) {
		commit('setHeaderHeight', height)
	},
}

const getters = {
	language: (state) => state.language,
	headerHeight: (state) => state.headerHeight,
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
