<template>
	<div class="container">
		<Header ref="headerRef"></Header>
		<div class="main">
			<!-- 标题 -->
			<div class="title" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1500" data-aos-easing="ease-out-sine">
				<span>{{ t('home.世界和平，享受旅游') }}</span>
			</div>
			<!-- 展示内容 -->
			<div id="content" class="content">
				<!-- 左侧内容 -->
				<div class="content-left">
					<div class="content-left-top">
						<img alt="" src="@/assets/image/appLogo.png" />
					</div>
					<div class="content-left-middle">
						<a :href="DownloadLink.google_play_url" target="_blank"><img alt="" src="@/assets/image/google_play.png" /></a>
						<a :href="DownloadLink.app_store_url" target="_blank"><img alt="" src="@/assets/image/app_store.png" /></a>
						<a v-show="isZh" :href="DownloadLink.app_store_url" target="_blank"><img alt="" src="@/assets/image/android_store.png" /></a>
						<a v-show="!isZh" :href="DownloadLink.android_store_en_url" target="_blank"><img alt="" src="@/assets/image/android_store_en.png" /></a>
						<a v-show="isZh" :href="DownloadLink.galaxy_store_cn_url" target="_blank"><img alt="" src="@/assets/image/galaxy_store_cn.png" /></a>
						<a v-show="!isZh" :href="DownloadLink.galaxy_store_en_url" target="_blank"><img alt="" src="@/assets/image/galaxy_store_en.png" /></a>
					</div>
					<div class="content-left-bottom">
						<img alt="" src="@/assets/image/iosApp.png" />
						<img alt="" src="@/assets/image/AndroidApp.png" />
						<div class="icon">
							<a :href="DownloadLink.youtube_light_url" target="_blank"> <img alt="" src="@/assets/image/youtube-light.png" /></a>
							<a :href="DownloadLink.bilibili_light_url" target="_blank"> <img alt="" src="@/assets/image/bilibili-light.png" /></a>
						</div>
					</div>
				</div>
				<!-- 右侧APP内容 -->
				<div v-show="isZh" class="content-right">
					<el-image
						v-for="(i, index) in srcCnList"
						:key="index"
						v-lazy="i"
						:hide-on-click-modal="true"
						:initial-index="4"
						:max-scale="7"
						:min-scale="0.2"
						:preview-src-list="srcCnList"
						:src="i"
						:zoom-rate="1.2"
						fit="cover"
						style="width: 20%"
					/>
				</div>
				<!-- 右侧APP内容 -->
				<div v-show="!isZh" class="content-right">
					<el-image
						v-for="(i, index) in srcEnList"
						:key="index"
						v-lazy="i"
						:hide-on-click-modal="true"
						:initial-index="4"
						:max-scale="7"
						:min-scale="0.2"
						:preview-src-list="srcEnList"
						:src="i"
						:zoom-rate="1.2"
						fit="cover"
						style="width: 20%"
					/>
				</div>
			</div>
			<!-- 生肖徽章 -->
			<div class="badge">
				<div v-for="i in badgeList" :key="i.id" class="badge-item">
					<img :src="i.ossUrl" alt="" />
					<el-button :icon="SoldOut" type="primary" @click="handleBadge(i)">{{ $t('home.购买') }}</el-button>
				</div>
			</div>
		</div>
		<Copyright></Copyright>
		<ParallaxMask></ParallaxMask>
		<ChinaLantern />
	</div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { computed, onMounted, reactive, ref } from 'vue'
import Copyright from '@/components/Copyright'
import Header from '@/components/Header'
import AOS from 'aos'
import { reqAllCommodityInfo, reqOrderQrcode, reqPriceAll, reqUserCommodityInfo } from '@/api/order'
import { useStore } from 'vuex'
import { ElMessage, ElMessageBox } from 'element-plus'
import { setToken } from '@/utils/auth'
import { SoldOut } from '@element-plus/icons-vue'
import ParallaxMask from '@/components/parallaxMask.vue'
import DownloadLink from '@/config/Links'
import ChinaLantern from '@/components/ChinaLantern.vue'

const { t, locale } = useI18n()
const store = useStore()

const isZh = computed(() => locale.value === 'zh-Hans')
const nameNo = computed(() => store.getters['user/nameNo'])

const badgeList = ref([])
const userBadgeData = ref([])
const rechargeData = ref([])
const headerRef = ref(null)
const srcCnList = reactive([
	require('@/assets/image/cn1.png'),
	require('@/assets/image/cn2.png'),
	require('@/assets/image/cn3.png'),
	require('@/assets/image/cn4.png'),
	require('@/assets/image/cn5.png'),
])
const srcEnList = reactive([
	require('@/assets/image/en1.png'),
	require('@/assets/image/en2.png'),
	require('@/assets/image/en3.png'),
	require('@/assets/image/en4.png'),
	require('@/assets/image/en5.png'),
])

async function getAllCommodity() {
	const res = await reqAllCommodityInfo({
		type: '徽章',
		badgeType: '生肖徽章',
	})
	if (res.code === 200) {
		badgeList.value = res.results
	}
}

async function getUserCommodity() {
	const res = await reqUserCommodityInfo({
		name: nameNo.value,
	})
	if (res?.code === 200) {
		userBadgeData.value = res.badgeAnimalSignList
	}
}

function handleBadge(item) {
	if (userBadgeData.value.some((element) => element.name === item.name)) {
		return ElMessage({
			message: t('home.您已拥有该徽章'),
			type: 'success',
		})
	}
	ElMessageBox.confirm(t('home.是否购买此徽章'), t('common.警告'), {
		confirmButtonText: t('common.确定'),
		cancelButtonText: t('common.取消'),
		type: 'warning',
	})
		.then(() => {
			if (!nameNo.value) {
				headerRef.value.showLoginDialog = true
				return ElMessage(t('home.请先登录'))
			}
			let requestData = {
				discountType: '网页支付',
				language: '',
				goldType: rechargeData.value[0]?.id,
				name: nameNo.value,
				isBuyDirectly: '1',
			}
			reqOrderQrcode(requestData).then((res) => {
				if (res.code === 200) {
					setToken('commodityId', item.id)
					document.querySelector('body').innerHTML = res.result
					document.forms[0].submit()
				}
			})
		})
		.catch(() => {})
}

async function getAllPrice() {
	const res = await reqPriceAll()
	if (res.code === 200) {
		rechargeData.value = res.result
	}
}

onMounted(() => {
	AOS.init()
	getAllCommodity()
	getAllPrice()
	if (nameNo.value) {
		getUserCommodity()
	}
})
</script>

<style lang="less" scoped>
.container {
	width: 100%;

	.main {
		width: 100%;
		padding: 20px;
		margin-bottom: 60px;

		.title {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			height: calc(100vh - 40px - 60px);

			span {
				font-size: 50px;
				font-weight: bold;
			}
		}

		.content {
			width: 100%;
			margin: 0 auto;
			display: flex;
			justify-content: space-around;
			align-items: center;
			height: 100vh;
			padding: 0 120px;

			.content-left {
				width: 17%;
				display: flex;
				justify-content: space-around;
				align-items: center;
				flex-direction: column;

				.content-left-top {
					text-align: center;

					img {
						width: 80%;
					}
				}

				.content-left-middle {
					width: 100%;
					margin: 40px auto;
					display: flex;
					justify-content: space-around;
					align-items: center;
					flex-wrap: wrap;

					a {
						width: 40%;

						img {
							width: 100%;
						}
					}
				}

				.content-left-bottom {
					width: 100%;
					display: flex;
					justify-content: space-around;
					align-items: center;

					img {
						width: 35%;
					}

					.icon {
						width: 20%;
						display: flex;
						flex-direction: column;
						text-align: center;

						img {
							width: 80%;
						}
					}
				}
			}

			.content-right {
				width: 75%;
				display: flex;
				align-items: center;
				justify-content: space-around;

				img {
					display: block;
					width: 20%;
				}
			}
		}

		.badge {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-around;
			flex-wrap: wrap;

			.badge-item {
				width: calc(100% / 6);
				padding: 20px;
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;

				img {
					width: 50%;
					transition: all 0.5s;
					margin-bottom: 20px;
					object-fit: contain;

					&:hover {
						transform: scale(1.2);
						cursor: pointer;
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.container {
		.main {
			margin-bottom: 20px;

			.title {
				height: 80vh;

				span {
					font-size: 30px;
					text-align: center;
				}
			}

			.content {
				padding: 0;
				display: inline;
				height: auto;

				.content-left {
					width: 100%;
					margin-bottom: 20px;

					.content-left-top {
						img {
							width: 50%;
						}
					}
				}

				.content-right {
					width: 100%;
					display: flex;
					flex-wrap: wrap;
				}
			}

			.badge {
				width: 100%;
				margin-top: 20px;
				flex-wrap: wrap;

				.badge-item {
					width: 32%;
				}
			}
		}
	}
}
</style>
